import React from 'react';
import './media_logos.css';
import * as images from './../../images';

const mediaLogos = [
  { src: images.LaRazon, alt: 'La Razón' },
  { src: images.Forbes, alt: 'Forbes' },
  { src: images.BusinessInsiders, alt: 'Business Insider' },
  { src: images.ElEconomista, alt: 'El economista' },
  { src: images.AxisNegocios, alt: 'Axis' },
  { src: images.Tekios, alt: 'TEKIOS' },
];

const MediaLogos = () => {
  return (
    <>
      <div className="media-logos-section">
        <h2 className='media-logos-title'>Momentu en los medios</h2>
        <div className="logos-container">
          {mediaLogos.map((logo, index) => (
            <img key={index} src={logo.src} alt={logo.alt} />
          ))}
        </div>
      </div>
      <div className="media-logos-section-mobile">
        <h2>Momentu en los medios</h2>
        <div className="logos-container">
          {mediaLogos.map((logo, index) => (
            <img key={index} src={logo.src} alt={logo.alt} />
          ))}
        </div>
      </div>
    </>
  );
}

export default MediaLogos;
