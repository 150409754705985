import React from 'react';
import * as images from './../../images/';
import './hero.css';
import Carousel, { companyLogos } from './../CompanyLogos';

const Hero = () => {
  return (
    <>
      <div className="hero-section">
        <div className="hero-content">
          <h3>EXPERTOS EN SEGUROS CORPORATIVOS</h3>
          <h1>Tu aliado digital en seguros y salud para el bienestar empresarial</h1>
          <p>Innovación en seguros corporativos: cobertura completa, costos reducidos.<br />
            Tu aliado estratégico en gestión de riesgos y bienestar laboral</p>
          <button className="hero-cta-button" onClick={() => {
            window.open('/contact-us', '_blank');
          }}>Reducir costos de mi empresa</button>
          <div className="text-container">
            <span>Tu equipo estará en buena compañía</span>
            <p>Miles de empleados en las compañías líderes de Latam nos recomiendan</p>
          </div>
          <div>
            <Carousel>
              {companyLogos.map((logo, index) => (
                <img key={index} src={logo.src} alt={logo.alt} />
              ))}
            </Carousel>
          </div>
        </div>
        <div className='hero-image-container'>
          <img src={images.Hero} alt="Empresa 1" />
        </div>
      </div>
      <div className="hero-section-mobile">
        <img src={images.Hero} alt="Empresa 1" />
        <h3>EXPERTOS EN SEGUROS CORPORATIVOS</h3>
        <h1>Tu aliado digital en seguros y salud para el bienestar empresarial</h1>
        <p>Innovación en seguros corporativos: cobertura completa, costos reducidos.<br />
          Tu aliado estratégico en gestión de riesgos y bienestar laboral
        </p>
        <button className="hero-cta-button" onClick={() => {
          window.open('/contact-us', '_blank');
        }}>Reducir costos de mi empresa</button>
        <span>Tu equipo estará en buena compañía</span>
        <p style={{ marginTop: 0 }}>Miles de empleados en las compañías líderes de Latam nos recomiendan</p>
        <Carousel>
          {companyLogos.map((logo, index) => (
            <img key={index} src={logo.src} alt={logo.alt} />
          ))}
        </Carousel>
      </div>
    </>
  );
}

export default Hero;
