import React from 'react';
import * as images from './../../images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

import './footer.css';

const Footer = () => {
  return (
    <>
      <footer className="footer-section">
        <div className="footer-social">
          <a href="https://www.instagram.com/tu.momentu/" rel="noreferrer" target='_blank'><FontAwesomeIcon icon={faInstagram} color='#1C38A9' /></a>
          <a href="https://www.linkedin.com/company/momentulatam/mycompany/" rel="noreferrer" target='_blank'><FontAwesomeIcon icon={faLinkedinIn} color='#1C38A9' /></a>
        </div>
        <div className="footer-links">
          <a href='/'><img src={images.Logo} className="footer-logo" alt={'logo'} /></a>
          <a href="https://wa.me/5215584212986?text=Hola quiero conocer sobre la plataforma" rel="noreferrer" target='_blank'>Solicita un diagnóstico</a>
          <a href="/contact-us">Agenda una demo</a>
          <a href="https://seguros.momentu.co/" rel="noreferrer" target='_blank'>Compara riesgos</a>
          <a href="https://coberturas.momentu.co/" rel="noreferrer" target='_blank'>Consulta coberturas</a>
        </div>
        <div className="footer-contact">
          <a href="mailto:conversemos@momentu.co"><FontAwesomeIcon icon={faEnvelope} color='#061A84' /> conversemos@momentu.co</a>
          <a href="https://wa.me/5215584212986?text=Hola quiero conocer sobre la plataforma"><FontAwesomeIcon icon={faWhatsapp} color='#061A84' /> +52 1 5584212986</a>
        </div>
        <a href="/politica-de-privacidad" className={'link'} target='_blank'>Política de privacidad</a>
        <span className={'address'}><a href={'https://maps.app.goo.gl/6QSfVWc3eJ16HYfC7'} target='_blank' rel="noreferrer">Calle Varsovia 36, Juárez, Cuauhtémoc,  CDMX, México</a></span>
        <span className={'address'}><a href={'https://maps.app.goo.gl/MiJAhRsasMfF2D826'} target='_blank' rel="noreferrer">44 Montgomery St, San Francisco, CA EE. UU.</a></span>
        <p className="copy-right">Copyright © 2023 Momentu</p>
      </footer>

      <footer className="footer-section-mobile">
        <div className="footer-social">
          <a href="https://www.instagram.com/tu.momentu/" rel="noreferrer" target='_blank'><FontAwesomeIcon icon={faInstagram} color='#1C38A9' /></a>
          <a href="https://www.linkedin.com/company/momentulatam/mycompany/" rel="noreferrer" target='_blank'><FontAwesomeIcon icon={faLinkedinIn} color='#1C38A9' /></a>
        </div>
        <div className="footer-links">
          <a href='/'><img src={images.Logo} className="footer-logo" alt={'logo'} /></a>
          <a href="https://wa.me/5215584212986?text=Hola quiero conocer sobre la plataforma" rel="noreferrer" target='_blank'>Solicita un diagnóstico</a>
          <a href="/contact-us">Agenda una demo</a>
          <a href="https://seguros.momentu.co/" rel="noreferrer" target='_blank'>Compara riesgos</a>
          <a href="https://coberturas.momentu.co/" rel="noreferrer" target='_blank'>Consulta coberturas</a>
        </div>
        <div className="footer-contact">
          <a href="mailto:conversemos@momentu.co"><FontAwesomeIcon icon={faEnvelope} color='#061A84' /> conversemos@momentu.co</a>
          <a href="https://wa.me/5215584212986?text=Hola quiero conocer sobre la plataforma"><FontAwesomeIcon icon={faWhatsapp} color='#061A84' /> +52 1 5584212986</a>
          <a href="/politica-de-privacidad" target='_blank'>Política de privacidad</a>
        </div>
        <p className="copy-right">Copyright © 2023 Momentu</p>
      </footer>
    </>
  );
}

export default Footer;
