import React, { useEffect, useRef } from 'react';
import * as images from './../../images';
import './sidebar.css';

const Sidebar = ({ isOpen, onClose }) => {
    const scrollYRef = useRef(window.scrollY);

    useEffect(() => {
        const body = document.body;
        scrollYRef.current = window.scrollY;
        if (isOpen) {
            body.style.position = 'fixed';
            body.style.top = `-${scrollYRef.current}px`;
            body.style.width = '100%';
            body.style.overflowY = 'hidden';
        } else {
            const scrollY = body.style.top;
            body.style.position = '';
            body.style.top = '';
            body.style.overflowY = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }
        return () => {
            body.style.position = '';
            body.style.top = '';
            body.style.overflowY = '';
            window.scrollTo(0, parseInt(scrollYRef.current || '0'));
        };
    }, [isOpen]);

    function navigateTo(value) {
        onClose();
        setTimeout(() => {
            window.scrollTo({ top: value, behavior: 'smooth' });
        }, 400);
    }

    return (
        <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}
            style={{
                transform: isOpen ? 'translateX(0)' : 'translateX(-100%)',
                transition: 'transform 0.3s ease-in-out'
            }}>
            <div className='header'>
                <img src={images.Logo} alt='logo' />
                <img src={images.Close} alt='close' onClick={onClose} />
            </div>
            <div className='content'>
                <div className='options'>
                    <button className={'menuItem'} onClick={() => {
                        navigateTo(1600);
                    }} >Para colaboradores</button>
                    <button className={'menuItem'} onClick={() => {
                        navigateTo(900);
                    }}>Para empresas</button>
                    <button className={'menuItem'} onClick={() => {
                        navigateTo(2400);
                    }}>Sobre nosotros</button>
                    <a href="https://wa.me/5215584212986?text=Hola quiero conocer sobre la plataforma" target='_blank' rel="noreferrer" className={'menuItem'} onClick={onClose}>Solicitar diagnóstico gratis</a>
                </div>
                <button className="btn" onClick={() => {
                    onClose();
                    window.open('https://lulasalud.com', '_blank')
                }}>Iniciar sesión</button>
            </div>
        </div>
    );
}

export default Sidebar;
