import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './whatsapp.css';
import HubspotForm from 'react-hubspot-form'
import { WhatsAppContext } from './context';

const WhatsAppButton = () => {
  const {
    showForm,
    openForm,
    closeForm,
    showOptions,
    toggleOptions,
   } = useContext(WhatsAppContext);

  const onToggleForm = () => {
    if (showForm) {
      closeForm();
    } else {
      toggleOptions();
      openForm();
    }
  };

  const onToggleOptions = () => {
    if (showForm) {
      closeForm();  
    }
    toggleOptions();
  }

  const options = [
    {
      name: 'Si necesitas soporte de ventas y cotización, da clic acá',
      action: onToggleForm
    },
    {
      name: 'Si necesitas soporte con tu póliza da clic acá',
      action: () => {
        window.open('https://wa.me/5215646685358?text=Tengo dudas sobre mi póliza', '_blank')
        onToggleOptions()
      }
    }
  ]

  return (
    <div className="whatsapp-button-container">
      <button className="whatsapp-button" onClick={onToggleOptions}>
        <FontAwesomeIcon icon={faWhatsapp} />
      </button>
      {showOptions && <Options options={options} />}
      {showForm && <Formulario onClose={closeForm} />}
    </div>
  );
};

const Options = ({options}) => {
  return (
    <div className='formulario-container' style={{'min-height': '150px'}}>
      {
        options.map(({name, action}) => (
          <button className='bnt' key={name} onClick={action}>{name}</button>
        ))
      }
    </div>
  );
};

const Formulario = (onClose) => {
  return (
    <div className="formulario-container" style={{'min-height': '470px'}}>
      <HubspotForm
        portalId='20394660'
        formId='b8db644b-80e9-44f9-8d53-ce91184c86b4'
        onSubmit={onClose}
        onReady={(form) => console.log('Form ready!')}
        loading={<div></div>}
      />
    </div>
  );
};

export default WhatsAppButton;