// WhatsAppContext.js
import React, { createContext, useState } from "react";

export const WhatsAppContext = createContext({
    showOptions: false,
    toggleOptions: () => {},
    showForm: false,
    openForm: () => { },
    closeForm: () => { },
});

export const WhatsAppProvider = ({ children }) => {
    const [showOptions, setShowOptions] = useState(false);
    const [showForm, setShowForm] = useState(false);

    const toggleOptions = () => {
        setShowOptions(!showOptions);
    };

    const openForm = () => {
        setShowForm(true);
    };

    const closeForm = () => {
        setShowForm(false);
    };

    return (
        <WhatsAppContext.Provider
            value={{
                showForm,
                openForm,
                closeForm,
                showOptions,
                toggleOptions,
            }}
        >
            {children}
        </WhatsAppContext.Provider>
    );
};
