import React from 'react';
import { Helmet } from 'react-helmet';
import Hero from './../components/Hero';
import Benefits from './../components/Benefits';
import Services from './../components/Services';
import InsuranceComparator from './../components/InsuranceComparator';
import CaseStudy from './../components/CaseStudy';
import MediaLogos from './../components/MediaLogos';
import Testimonials from './../components/Testimonials';
import Ebooks from './../components/Ebooks';
import Blogs from './../components/Blog';
import Webinars from './../components/Webinar';
import Footer from './../components/Footer';

function Home() {
  return (
    <>
      <Helmet>
        <title>Momentu - Plataforma de Salud Empresarial Digital | Cuida la Salud de tu Equipo</title>
        <meta name="description" content="Descubre Momentu, la primera plataforma 100% digital para la salud empresarial. Reducimos los costos en beneficios mientras cuidamos la salud de tu equipo. Gestiona seguros, salud mental, conserje 24/7 y métricas de bienestar. ¡Cotiza tu seguro hoy mismo!"/>
      </Helmet>
      <Hero/>
      <Benefits />
      <Services />
      <InsuranceComparator />
      <CaseStudy />
      <MediaLogos />
      <Testimonials />
      <Blogs />
      <Ebooks />
      <Webinars />
      <Footer />
    </>
  );
}

export default Home;
