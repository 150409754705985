import React from 'react';
import './testimonials.css';
import * as images from './../../images'

const testimonials = [
    {
        author: "Paola Madriñán",
        title: "Chief People Officer de Siigo",
        quote: "Momentu nos ofrece un panorama completo de las necesidades de nuestros colaboradores y con base en eso diseñar planes de acción personalizados.",
        src: images.CPO_Siigo
    },
    {
        author: "Gisela López",
        title: "VP of People de La Haus",
        quote: "Hemos visto un notable incremento en el compromiso del equipo al sentirse escuchados y valorados. Mayor motivación por estar saludables y una notable satisfacción laboral y sentido de pertenencia y orgullo por la empresa.",
        src: images.VP_LaHaus
    },
    {
        author: "Ángela Garcés",
        title: "Gerente de H.R de Coquecol",
        quote: "Momentu ha sido un aliado valioso para prevenir, gestionar y mejorar el bienestar emocional de nuestros colaboradores en el Grupo empresarial.",
        src: images.GHR_Coquecol
    },
    {
        author: "Sebastián Ponce de León",
        title: "Chief People Officer de Tül",
        quote: "Los líderes de los equipos nos escriben que sienten a sus equipos más tranquilos, felices, enérgicos y ya estamos viendo cómo eso se traduce en los resultados de negocio.",
        src: images.CPO_Tul
    },
];

const Testimonials = () => {
    return (
        <>
            <div className="testimonials-section">
                <h2>Lo que dicen nuestros clientes</h2>
                <div className="testimonials-container">
                    {testimonials.map((testimonial, index) => (
                        <div className='card' key={index}>
                            <div className='author'>
                                <img src={testimonial.src} className='avatar' alt={testimonial.author} />
                                <div className='info'>
                                    <span className={'title'}>{testimonial.author}</span>
                                    <span className={'subtitle'}>{testimonial.title}</span>
                                </div>
                            </div>
                            <span className={'text'}>{testimonial.quote}</span>
                        </div>
                    ))}
                </div>
            </div>
            <div className="testimonials-section-mobile">
                <h2>Lo que dicen nuestros clientes</h2>
                <div className="testimonials-container">
                    {testimonials.map((testimonial, index) => (
                        <div className='card' key={index}>
                            <div className='author'>
                                <img src={testimonial.src} className='avatar' alt={testimonial.author} />
                                <div className='info'>
                                    <span className={'title'}>{testimonial.author}</span>
                                    <span className={'subtitle'}>{testimonial.title}</span>
                                </div>
                            </div>
                            <span className={'text'}>{testimonial.quote}</span>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default Testimonials;
