import React from 'react';
import * as images from './../../images';
import './blog.css';

const blogList = [
  { src: images.Blog3, title: 'KPIs definitivos: los indicadores que deberías estar monitorear en tu estrategia de RR.HH.', url: '/blog/kpis-definitivos', type: 'Blog', description: '¿Sabes cuáles son esos indicadores que son como un faro para tu estrategia? ¿Aquellos que, al medirlos, te ayudan a entender si tu empresa va marchando bien o quizá hay mejoras por hacer?' },
  { src: images.Blog1, title: 'Desbloqueando el potencial laboral: lo mejor del trabajo remoto vs. presencial', url: '/blog/desbloqueando-el-potencial-laboral', type: 'Blog', description: 'En el actual escenario laboral, el dinamismo entre el trabajo remoto y presencial ha marcado un cambio radical en la forma en que concebimos y llevamos a cabo nuestras responsabilidades profesionales' },
  { src: images.Blog2, title: 'El bienestar como KPI: cómo medir la satisfacción real y el potencial de tu equipo', url: '/blog/bienestar-como-kpi', type: 'Blog', description: 'En el  mundo empresarial de hoy donde las empresas compiten no solo por clientes sino también por talento, el concepto de bienestar del equipo ha emergido como una fuerza motriz clave detrás del éxito sostenible de las organizaciones' },
  { src: images.Blog5, title: 'Domina tus números: lo que debes saber para brillar con tu presupuesto de RR.HH', url: '/blog/domina-tus-numeros', type: 'Blog', description: 'HBR relata la historia de cuando el Museo Guggenheim de Bilbao abrió en 1997, los críticos elogiaron la obra arquitectónica de Frank Gehry como una maravilla del siglo' },
  { src: images.Blog4, title: 'Todo para entender y medir correctamente la rotación de tu equipo', url: '/blog/rotacion', type: 'Blog', description: 'En el mundo empresarial donde el éxito se moldea a través de la calidad de tu equipo, surge un factor clave que todo líder o estratega de RR.HH. debe tener en cuenta: la rotación de sus empleados.' },
  { src: images.Blog4, title: 'El ausentismo en tu empresa: cómo entenderlo, medirlo y combatirlo', url: '/blog/calculadora-ausentismo', type: 'Blog', description: 'El ausentismo laboral se presenta como un desafío significativo que puede afectar la productividad y la salud organizacional.' },
];

const Blog = () => {
  return (
    <>
      <div className="blogs-section">
        <h2 className='blogs-title'>Blogs</h2>
        <div className="blogs-container">
          <div style={{ overflowX: 'auto', display: 'flex', flexDirection: 'row', gap: 48 }}>
            {blogList.map((value, i) => (
              <div className='blog-container'>
                <img className='blog--image-container' src={value.src} alt={`blog_${i + 1}`} />
                <div>
                  <div className='blog-badge'>
                    <span>{value.type}</span>
                  </div>
                </div>
                <h1>{value.title}</h1>
                <p>{value.description}</p>
                <button onClick={() => {
                  window.open(value.url, '_blank');
                }} style={{ paddingLeft: 0 }}>Descargar</button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="blogs-section-mobile">
        <h2 >E-books y materiales descargables</h2>
        <div style={{ overflowX: 'auto', display: 'flex', flexDirection: 'row', gap: 16 }}>
          {blogList.map((value, i) => (
            <div className='blog-container'>
              <img className='blog--image-container' src={value.src} alt={`blog_${i + 1}`} />
              <div>
                <div className='blog-badge'>
                  <span>{value.type}</span>
                </div>
              </div>
              <h1>{value.title}</h1>
              <p>{value.description}</p>
              <button onClick={() => {
                window.open(value.url, '_blank');
              }}>Descargar</button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Blog;
