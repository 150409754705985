import React from 'react';
import * as images from './../../images';
import './services.css';

const Services = () => {
  return (
    <>
      <section id='sobre-nosotros' className="services-section">
        <div className='content'>
          <p>VENTAJAS</p>
          <h2>Con Momentu tu empresa cuenta con servicios integrales</h2>
          <p>El broker de salud que cuida a tus colaboradores</p>
          <button className="service-button" onClick={() => {
            window.open('/contact-us', '_blank');
          }}>Quiero una cotización</button>
        </div>

        <div className='services-grid-container'>
          <div className="services-grid">
            <img src={images.AttentionWithDoctors} alt={'AttentionWithDoctors'} />
            <img src={images.NutrionalAdvice} alt={'NutrionalAdvice'} />
            <img src={images.PsycohlogicalAttention} alt={'PsycohlogicalAttention'} />
            <img src={images.NOM035} alt={'NOM035'} />
          </div>
        </div>
      </section>
      <section id='sobre-nosotros' className="services-section-mobile">
        <div className="services-grid">
          <img src={images.AttentionWithDoctors} alt={'AttentionWithDoctors'} />
          <img src={images.NutrionalAdvice} alt={'NutrionalAdvice'} />
          <img src={images.PsycohlogicalAttention} alt={'PsycohlogicalAttention'} />
          <img src={images.NOM035} alt={'NOM035'} />
        </div>
        <p className={'title'}>VENTAJAS</p>
        <h2>Con Momentu tu empresa cuenta con servicios integrales</h2>
        <p>El broker de salud que cuida a tus colaboradores</p>
        <button onClick={() => {
          window.open('/contact-us', '_blank');
        }}>
          Quiero una cotización
        </button>


      </section>
    </>
  );
}

export default Services;
