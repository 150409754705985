import React from 'react';
import * as images from './../../images';
import './header.css';

function Header({ open }) {
  return (
    <>
      <header className="header">
        <div className="logo-container">
          <a href='/'>
            <img src={images.Logo} alt="Logo" />
          </a>
        </div>
        <nav className="navigation">
          <ul>
            <li><a href="/#colaboradores">Para colaboradores</a></li>
            <li><a href="/#empresas">Para empresas</a></li>
            <li><a href="/#sobre-nosotros">Sobre nosotros</a></li>
            <li><a href="https://wa.me/5215584212986?text=Hola quiero conocer sobre la plataforma" target='_blank' rel="noreferrer">Solicitar diagnóstico gratis</a></li>
          </ul>
        </nav>
        <div className="header-buttons">
          {/* <button className="btn btn-primary">Registrarse</button> */}
          <button className="btn" onClick={() => {
            window.open('https://lulasalud.com', '_blank')
          }}>Iniciar sesión</button>
        </div>
      </header>

      <header className="header-mobile">
        <img src={images.LogoMobile} alt="Logo" />
        <img src={images.Menu} alt="Menu" onClick={open} />
      </header>
    </>
  );
}

export default Header;
