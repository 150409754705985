import React, { useContext } from 'react';
import { WhatsAppContext } from './../WhatsAppButton/context';
import './benefits.css';

const Benefits = () => {
  const { openForm } = useContext(WhatsAppContext);

  return (
    <>
      <div className="benefits-section">
        <section id='empresas' className="benefit" style={{ backgroundColor: '#FAFAFA' }}>
          <div className='content'>
            <div>
              <h3 className='benefit-subtitle'>TENER A MOMENTU COMO ALIADO SIGNIFICA</h3>
              <h2 className='benefit-title'>Más impacto para tu empresa</h2>
            </div>
            <ul>
              <li><span>Reducción hasta 30%</span> gastos en salud</li>
              <li>Gestionamos todos tus seguros y <span>beneficios en un solo lugar</span></li>
              <li>Tendrás <span>acceso a métricas y analítica</span> del bienestar de tu equipo</li>
              <li>Cumple la <span>nom 35</span> y evita sanciones</li>
              <li>Utilizamos <span>IA para gestionar los siniestros</span>, liberándote de carga operacional</li>
            </ul>
            <button className="benefit-button" onClick={() => {
              window.open('/contact-us', '_blank');
            }}>Ahorra en salud</button>
          </div>
        </section>
        <section id='colaboradores' className="benefit">
          <div className='content'>
            <div>
              <h3 className='benefit-subtitle'>TENER A MOMENTU COMO ALIADO SIGNIFICA</h3>
              <h2 className='benefit-title'>Más recursos para tus colaboradores</h2>
            </div>
            <ul>
              <li>Atención personalizada 24 - 7</li>
              <li>Los guiamos en sus siniestros, reembolsos y reclamos</li>
              <li>Acceso a psicólogos y nutricionistas para el bienestar</li>
              <li>Servicio omni-canal, acomodándonos a tus necesidades</li>
              <li>Servicios médicos gratis</li>
            </ul>
            <button className="benefit-button" onClick={openForm}>Descubre la plataforma</button>
          </div>
        </section>
      </div>
      <div className="benefits-section-mobile">
        <section id='empresas' className="benefit" style={{ backgroundColor: '#FAFAFA' }}>
          <h3>TENER A MOMENTU COMO ALIADO SIGNIFICA</h3>
          <h2>Más impacto para tu empresa</h2>
          <ul>
            <li>Reducción hasta 30% gastos en salud</li>
            <li>Gestionamos todos tus seguros y beneficios en un solo lugar</li>
            <li>Tendrás acceso a métricas y analítica del bienestar de tu equipo</li>
            <li>Cumple la nom 35 y evita sanciones</li>
            <li>Utilizamos IA para gestionar los siniestros, liberándote de carga operacional</li>
          </ul>
          <button onClick={() => {
            window.open('/contact-us', '_blank');
          }}>Ahorra en salud</button>
        </section>
        <section id='colaboradores' className="benefit">
          <h3>TENER A MOMENTU COMO ALIADO SIGNIFICA</h3>
          <h2>Más recursos para tus colaboradores</h2>
          <ul>
            <li>Atención personalizada 24 - 7</li>
            <li>Los guiamos en sus siniestros, reembolsos y reclamos</li>
            <li>Acceso a psicólogos y nutricionistas para el bienestar</li>
            <li>Servicio omni-canal, acomodándonos a tus necesidades</li>
            <li>Servicios médicos gratis</li>
          </ul>
          <button onClick={openForm}>Descubre la plataforma</button>
        </section>
      </div>
    </>
  );
}

export default Benefits;
