import React, { useContext } from 'react';
import { WhatsAppContext } from './../WhatsAppButton/context';
import * as images from './../../images';
import './case_study.css';

const CaseStudy = () => {
  const { openForm } = useContext(WhatsAppContext);
  return (
    <>
      <div className="case-study-section">
        <div className="case-study-content">
          <p>CASO DE ESTUDIO</p>
          <h2>Potenciar la gestión es ahorrar dinero a tu empresa</h2>
          <p className="context"><span>Contexto:</span> Una empresa de tecnología + retail con operación en 2 países: Más de 50 colaboradores en México solicitaban al broker anterior gestionar las altas y bajas pero no revisaron el detalle</p><br />
          <p className="results"><span>Resultados:</span> Con tecnología logramos encontrar que:</p>
          <ul>
            <li>Existían <span>+20 bajas sin gestionar</span></li>
            <li><span>+10 Altas sin gestionar</span></li>
            <li>La empresa estuvo pagando <span>sobre-costos</span> por no gestionados de <span>45%</span></li>
          </ul>
          <span className="subtext">
            ¿Suena a un caso ajeno? Hemos detectado que sucede en el 60% de las empresas.<br /><br />
            ¿Pasa en la tuya?
          </span>
          <button className="case-studio-button" onClick={openForm}>Agendar prueba gratis</button>
        </div>
        <div className="case-study-actions">
          <img className='case-study-bg' src={images.CaseStudyBg} alt="background" />
        </div>
      </div>
      <div className="case-study-section-mobile">
        <img src={images.CaseStudyBg} alt="background" />
        <p className='title'>Caso de estudio</p>
        <h2>Potenciar la gestión es ahorrar dinero a tu empresa</h2>
        <p className="context"><span>Contexto:</span> Una empresa de tecnología + retail con operación en 2 países: Más de 50 colaboradores en México solicitaban al broker anterior gestionar las altas y bajas pero no revisaron el detalle</p><br />
        <p className="results"><span>Resultados:</span> Con tecnología logramos encontrar que:</p>
        <ul>
          <li>Existían +20 bajas sin gestionar</li>
          <li>+10 Altas sin gestionar</li>
          <li>La empresa estuvo pagando sobre-costos por no gestionados de 45%</li>
        </ul>
        <span className="subtext">
          ¿Suena a un caso ajeno? Hemos detectado que sucede en el 60% de las empresas.<br /><br />
          ¿Pasa en la tuya?
        </span>
        <button onClick={openForm}>Agendar prueba gratis</button>
      </div>
    </>
  );
}

export default CaseStudy;
