import React from 'react';
import * as images from './../../images';
import './webinar.css';

const webinarList = [
  { src: images.Webinar1, title: 'Construyamos tu plan de Beneficios', url: 'https://landing.momentu.co/es/transmision-plan-beneficios-2024', type: 'Webinar', description: 'Haz brillar tu área de RRHH y conviértete en un Great Place To Work' },
  { src: images.Webinar2, title: 'Navegando la Reforma Laboral en México', url: 'https://landing.momentu.co/es/transmision-reformalaboral', type: 'Webinar', description: 'La Reforma Laboral en México se ha convertido en un tema central de discusión durante este 2023, reuniendo a líderes y trabajadores del país en una conversación que redefine el panorama laboral. ' },
  { src: images.Webinar3, title: 'Domina tus números de RR.HH: cómo construir el presupuesto perfecto para 2024', url: 'https://landing.momentu.co/es/transmision-domina-tus-numeros-de-recursos-humanos', type: 'Webinar', description: 'Conectaremos las ideas de dos líderes top de la industria que compartirán sus trucos, estrategias e insights que te permitirán pasar de un presupuesto común a uno lleno de estrategias y eficiencia.' },
  { src: images.Webinar4, title: '¿Humana o Artificial? lo mejor de ambas inteligencias.', url: 'https://landing.momentu.co/evento-talks-inteligencia-humana-o-digital-tp', type: 'Webinar', description: 'Un panel diseñado para que aprendas los mejores casos de éxitos junto a expertos de la industria.' },
  { src: images.Webinar7, title: 'Transforma tus procesos de RRHH con ChatGPT', url: 'https://landing.momentu.co/es/transmision-rrhh-con-chatgpt', type: 'Webinar', description: 'Descubre cómo la combinación de I.A. y RR.HH. puede cambiar tu forma de trabajar.' },
  { src: images.Webinar6, title: 'Transforma tus procesos de RRHH con ChatGPT, Clase #2 ', url: 'https://landing.momentu.co/es/transmision-rrhh-con-chatgpt-clase2', type: 'Webinar', description: 'Profundiza en este segunda sesión cómo la combinación de I.A. y RR.HH. puede cambiar tu forma de trabajar.' },
  { src: images.Webinar5, title: 'Transforma tus procesos de RRHH con ChatGPT, Clase #3', url: 'https://landing.momentu.co/es/transmision-rrhh-con-chatgpt-clase3', type: 'Webinar', description: 'Muy pronto será nuestra tercera sesión, profundizaremos en KPIs y cómo la combinación de I.A. y RR.HH. puede cambiar tu forma de trabajar.' },
  { src: images.Webinar8, title: 'People Analytics', url: 'https://landing.momentu.co/es/transmision-people-analytics', type: 'Webinar', description: 'Descubre el poder de People Analytics y transforma tus datos en conocimiento impactante.' },
  { src: images.Webinar9, title: 'Inteligencia Artificial + RRHH. 🤖', url: 'https://landing.momentu.co/es/transmision-evento-inteligencia-artificial-y-recursos-humanos', type: 'Webinar', description: '60 minutos para entender los próximos 60 años.' },
];

const Webinar = () => {
  return (
    <>
      <div className="webinars-section">
        <h2 className='webinars-title'>Eventos</h2>
        <div className="webinars-container">
          <div style={{ overflowX: 'auto', display: 'flex', flexDirection: 'row', gap: 48 }}>
            {webinarList.map((value, i) => (
              <div className='webinar-container'>
                <img className='webinar--image-container' src={value.src} alt={`webinar_${i + 1}`} />
                <div>
                  <div className='webinar-badge'>
                    <span>{value.type}</span>
                  </div>
                </div>
                <h1>{value.title}</h1>
                <p>{value.description}</p>
                <button onClick={() => {
                  window.open(value.url, '_blank');
                }} style={{paddingLeft: 0}}>Ver grabación</button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="webinars-section-mobile">
        <h2>Eventos</h2>
        <div style={{ overflowX: 'auto', display: 'flex', flexDirection: 'row', gap: 16 }}>
          {webinarList.map((value, i) => (
            <div className='webinar-container'>
              <img className='webinar--image-container' src={value.src} alt={`webinar_${i + 1}`} />
              <div>
                <div className='webinar-badge'>
                  <span>{value.type}</span>
                </div>
              </div>
              <h1>{value.title}</h1>
              <span className='description'>{value.description}</span>
              <button onClick={() => {
                window.open(value.url, '_blank');
              }}>Ver grabación</button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Webinar;
