import React from 'react';
import * as images from './../../images'
import './insurance_comparator.css';

const InsuranceComparator = () => {
  return (
    <>
      <div className="insurance-comparator-section">
        <div className="comparator-content">
          <p>COMPARADOR DE SEGUROS</p>
          <h2>Te ayudamos a tomar la mejor decisión para tu empresa</h2>
          <p style={{ fontFamily: 'Red Hat Text' }}>Aseguramos ahorros y el servicio más completo de salud</p>
          <button className="comparator-button" onClick={() => {
            window.open('https://coberturas.momentu.co/', '_blank');
          }}>Ir al comparador</button>
        </div>
        <div className="comparator-image">
          <img src={images.Comparator} alt="Comparador de seguros" />
        </div>
      </div>
      <div className="insurance-comparator-section-mobile">
        <img src={images.Comparator} alt="Comparador de seguros" />
        <p className='title'>Comparador de seguros</p>
        <h2>Te ayudamos a tomar la mejor decisión para tu empresa</h2>
        <p>Aseguramos ahorros y el servicio más completo de salud</p>
        <button onClick={() => {
          window.open('https://coberturas.momentu.co/', '_blank');
        }}>
          Ir al comparador
        </button>
      </div>
    </>
  );
}

export default InsuranceComparator;
