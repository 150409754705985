import React from 'react';
import './hero.css';
import Carousel, { companyLogos } from './../CompanyLogos';
import HubspotForm from 'react-hubspot-form'

const HeroContact = () => {
  return (
    <>
      <div className="hero-contact-section">
        <div className="hero-contact-content">
          <h3>EXPERTOS EN SEGUROS CORPORATIVOS</h3>
          <h1>Tu aliado digital en seguros y salud para el bienestar empresarial</h1>
          <ul>
            <li>Tu seguro con la misma cobertura y más barato</li>
            <li>Todo en un solo lugar</li>
            <li>Incrementa tu valor, no tu costo</li>
          </ul>
          <div>
            <Carousel>
              {companyLogos.map((logo, index) => (
                <img key={index} src={logo.src} alt={logo.alt} />
              ))}
            </Carousel>
          </div>
        </div>
        <div className='hero-contact-form-container'>
          <div className={'hero-contact-form'}>
            <HubspotForm
              portalId='20394660'
              formId='2198ad42-f9b9-445a-ae32-a8e84f3cf7b6'
              onSubmit={() => console.log('Submit!')}
              onReady={(form) => console.log('Form ready!')}
              loading={<div></div>}
            />
          </div>
        </div>
      </div>
      <div className="hero-section-mobile">
        <div className='hero-contact-form-container'>
          <div className={'hero-contact-form'}>
            <HubspotForm
              portalId='20394660'
              formId='2198ad42-f9b9-445a-ae32-a8e84f3cf7b6'
              onSubmit={() => console.log('Submit!')}
              onReady={(form) => console.log('Form ready!')}
              loading={<div></div>}
            />
          </div>
        </div>
        <h3>EXPERTOS EN SEGUROS CORPORATIVOS</h3>
        <h1>Tu aliado digital en seguros y salud para el bienestar empresarial</h1>
        <ul>
          <li>Tu seguro con la misma cobertura y más barato</li>
          <li>Todo en un solo lugar</li>
          <li>Incrementa tu valor, no tu costo</li>
        </ul>
        <Carousel>
          {companyLogos.map((logo, index) => (
            <img key={index} src={logo.src} alt={logo.alt} />
          ))}
        </Carousel>
      </div>
    </>
  );
}

export default HeroContact;
