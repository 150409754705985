import React from 'react';
import * as images from './../../images';
import './e_books.css';

const ebookList = [
  { src: images.eBook1, title: 'Domina tus números de RR.HH: Cómo armar tu presupuesto ideal de RR.HH.', url: 'https://landing.momentu.co/ebook-dominatusnumeros', type: 'E-book', description: 'Creamos una guía práctica con todos los tips, insights y trucos que debes saber para crear tu presupuesto ideal de RR.HH.' },
  { src: images.eBook2, title: 'ChatGPT para Recursos Humanos: Los prompts que todo líder de H.R. debe dominar.', url: 'https://landing.momentu.co/ebook-chatgpt-para-recursos-humanos', type: 'E-book', description: 'Creamos una guía práctica con todos los tips, insights y trucos que debes saber para usar ChatGPT en tu estrategia de RR.HH. ' },
  { src: images.eBook3, title: 'Trabajo remoto vs. presencial, una guía para sacar el mayor provecho de ambas modalidades', url: 'https://landing.momentu.co/ebook-trabajo-presencial-vs-remoto', type: 'E-book', description: 'En este E-Book de nuestro #ToolkitMomentu hemos seleccionado algunas de las tendencias más relevantes en el trabajo remoto y presencial para que, como líder de Recursos Humanos, puedas aprovechar al máximo ambas modalidades en tu empresa y equipo.' },
  { src: images.eBook4, title: 'Los KPI’s definitivos de tu estrategia de RR.HH.', url: 'https://landing.momentu.co/ebook-kpis-definitivos-de-hhrr', type: 'E-book', description: 'Seguro que ya estás rastreando aspectos importantes en tu estrategia, pero con este E-Book te mostraremos los KPIs definitivos que realmente marcarán una diferencia en el crecimiento de tu empresa y en tus logros. ¿Estás listo para implementarlos?' },
  { src: images.eBook5, title: 'Calculadora de rotación de personal', url: 'https://landing.momentu.co/calculadoraderotacion', type: 'Template', description: 'Una herramienta para medir, analizar, comparar y tomar decisiones que mejoren tu ROI y tus metas.' },
  { src: images.eBook6, title: '8 Herramientas de Inteligencia Artificial para Recursos Humanos.', url: 'https://landing.momentu.co/herramientas-de-inteligencia-artificial-para-recursos-humanos', type: 'E-book', description: '¿Estás listo para darle un giro radical a tu estrategia de HR? Hemos seleccionado las 8 herramientas más útiles y sorprendentes que todos los líderes y profesionales de RH deberían conocer.' },
  { src: images.eBook7, title: 'Calculadora de bienestar, nps y costos', url: 'https://landing.momentu.co/calculadoradebienestar', type: 'Toolkit', description: '¡Maximiza el bienestar y optimiza tus resultados empresariales. Creamos esta poderosa herramienta que combina los KPI´s tradicionales de RRHH para obtener una visión clara de la felicidad de tu equipo y los costos que pueden estar frenando tu éxito.' },
  { src: images.eBook8, title: 'Presentación definitiva de recursos humanos', url: 'https://landing.momentu.co/presentacion-recursos-humanos', type: 'Template', description: '¿Pierdes tiempo valioso a la hora de hacer presentaciones y comunicar ideas o resultados? Te presentamos nuestra plantilla personalizada para RRHH, una herramienta a tu medida para hacerte la vida más fácil.' },
  { src: images.eBook9, title: 'Calculadora de ausentismo', url: 'https://landing.momentu.co/calculadoradeausentismo', type: 'Template', description: 'En Momentu te ayudamos a calcular y mejorar el ausentismo de tu empresa con una calculadora hecha a tu medida.' },
];

const Ebooks = () => {
  return (
    <>
      <div className="ebooks-section">
        <h2 className='ebooks-title'>E-books y materiales descargables</h2>
        <div className="ebooks-container">
          <div style={{ overflowX: 'auto', display: 'flex', flexDirection: 'row', gap: 48 }}>
            {ebookList.map((value, i) => (
              <div className='ebook-container'>
                <img className='ebook--image-container' src={value.src} alt={`ebook_${i + 1}`} />
                <div>
                  <div className='ebook-badge'>
                    <span>{value.type}</span>
                  </div>
                </div>
                <h1>{value.title}</h1>
                <p>{value.description}</p>
                <button onClick={() => {
                  window.open(value.url, '_blank');
                }} style={{paddingLeft: 0}}>Descargar</button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="ebooks-section-mobile">
        <h2 >E-books y materiales descargables</h2>
        <div style={{ overflowX: 'auto', display: 'flex', flexDirection: 'row', gap: 16 }}>
          {ebookList.map((value, i) => (
            <div className='ebook-container'>
              <img className='ebook--image-container' src={value.src} alt={`ebook_${i + 1}`} />
              <div>
                <div className='ebook-badge'>
                  <span>{value.type}</span>
                </div>
              </div>
              <h1>{value.title}</h1>
              <p>{value.description}</p>
              <button onClick={() => {
                window.open(value.url, '_blank');
              }}>Descargar</button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Ebooks;
