import React from 'react';
import './hero.css';

const HeroPolicies = () => {
  return (
    <>
      <div className="hero-policies-section">
        <div className="hero-policies-content">
          <h3>Política de Privacidad</h3>
          <h1>Política de Protección y Tratamiento de Datos Personales</h1>
          <p>En cumplimiento a lo dispuesto en la Ley estatutaria 1581 de 2012 y su Decreto Reglamentario 1377 de 2013, la plataforma adopta la presente política para el tratamiento de datos personales.</p>
          <p>La Política de Tratamiento tiene como objeto proteger el derecho constitucional del Habeas Data, el cual vela por el derecho que tienen todas las personas para conocer, actualizar, y rectificar la información que se haya recogido y almacenado en las bases de datos de la plataforma, por lo anterior, la Plataforma únicamente recolectará y dará el Tratamiento a Datos Personales, cuando así lo haya autorizado previamente el titular, para lo cual, implementará, medidas claras sobre confidencialidad y privacidad de los Datos Personales.</p>
          <p>En el desarrollo de la prestación de los servicios ofrecidos por la plataforma, se recolectarán los siguientes datos de los usuarios: nombre, correo electrónico, números telefónicos, entre otros.</p>
          <h2>1. Identificación del responsable del tratamiento de la información:</h2>
          <p>Nombre de la entidad: Plataforma Momentu, que en adelante se denominará la Plataforma, es una sociedad por acciones simplificada, creada en la cámara de comercio el 6 de julio de 2020, y tiene por objeto el suministro continuo de servicios de psicología, apoyo emocional, manejo de estrés y bienestar corporativo, entre otros.</p>
          <p>Identificación: NIT 901.391.748-5.<br />Dirección: Calle 74 # 4-62</p>
          <h2>2. Marco Legal:</h2>
          <p>Constitución Política, articulo 15 de la Ley 1266 de 2008, Ley 1581 de 2012, Decretos Reglamentarios 1727 de 2009 y 2952 de 2010.</p>
          <h2>3. Ámbito de aplicación:</h2>
          <p> La presente política será aplicable a cualquier tipo de datos personales registrados en la base de datos de la plataforma y cuyo titular sea una persona natural.</p>
          <h2>4. Definiciones</h2>
          <p>
            <strong>a. Autorización:</strong> Consiste en el consentimiento previo, expreso e informado del Titular para llevar a cabo el Tratamiento de datos personales.<br /><br />
            <strong>b. Aviso de privacidad:</strong> Hace referencia a la comunicación verbal o escrita generada por el Responsable del Tratamiento, dirigida al Titular para el tratamiento de sus datos personales, mediante la cual se le informa acerca de la existencia de las policitas de tratamiento de información que le serán aplicables.<br /><br />
            <strong>c. Base de datos:</strong> Corresponde al conjunto de datos personales que es objeto de tratamiento.<br /><br />
            <strong>d. Datos personales:</strong> Hacen referencia a cualquier información vinculada a una o varias personas naturales.<br /><br />
            <strong>e. Datos sensibles:</strong> Son aquellos datos que afecten la intimidad del titular, que en caso de uso indebido pueden causar una discriminación, como por ejemplo aquellos datos que determinen el origen racial, la orientación política, las convicciones religiosas o la pertenencia a sindicatos, organizaciones sociales, entre otros.<br /><br />
            <strong>f. Datos públicos:</strong> Son aquellos que no son considerados semiprivados, privados o sensibles. Entre estos se encuentran los datos relativos al estado civil de las personas, a su profesión u oficio, al igual que su calidad de comerciante. Son todos aquellos que se encuentran en documentos públicos y que no estén sometidos a reserva.<br /><br />
            <strong>g. Encargado del tratamiento:</strong> Corresponde a la persona natural o jurídica, que realice el tratamiento de los datos personales por instrucción del responsable del tratamiento de los datos.<br /><br />
            <strong>h. Responsable del tratamiento:</strong> Es aquella persona jurídica o natural, quien tenga el control sobre la base de datos y el tratamiento de estos.<br /><br />
            <strong>i. Titular:</strong> Hace referencia a la persona natural cuyos datos personales sean objeto de tratamiento.<br /><br />
            <strong>j. Tratamiento:</strong> Corresponde a cualquier tipo de operación sobre los datos personales, dentro de las que se encuentran: (i) la recolección, (ii) almacenamiento, (iii) uso, (iv) circulación o supresión.
          </p>
          <h2>5. Finalidades y tratamientos que se le da a los datos personales:</h2>
          <p>Los datos personales que la Plataforma recolecte, almacene, use o circule, tendrán las siguientes finalidades:</p>
          <ul>
            <li>1. Promoción de los servicios ofrecidos por la Plataforma</li>
            <li>2. Difusión de los servicios ofrecidos por la Plataforma</li>
            <li>3. Desarrollo de eventos, congresos, seminarios, cursos en virtud del objeto de la Plataforma.</li>
            <li>4. Envío periódico de comunicaciones al correo registrado, relacionadas con el desarrollo del objeto social de la Plataforma.</li>
          </ul>
          <h2>6. Derechos de los titulares de la información:</h2>
          <p>De conformidad con lo señalado por el articulo 8 de la Ley 1581 de 2012, el Titular de los datos personales tiene los siguientes derechos:</p>
          <ul>
            <li>1. Conocer, actualizar y rectificar sus datos personales en su calidad de responsable y Encargado del tratamiento.</li>
            <li>2. Solicitar prueba de la autorización otorgada a la plataforma. Ser informado por la Plataforma respecto del uso que se les ha dado a sus datos personales.</li>
            <li>3. Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en la Ley Estatutaria 1581 de 2012, habiéndose agotado el trámite de consulta o reclamo según lo indicado en la mencionada Ley.</li>
            <li>4. Revocar la autorización y/o solicitar la supresión del dato cuando en el Tratamiento no se respeten los principios, derechos y garantías constitucionales y legales.</li>
            <li>5. Acceder de forma gratuita a sus datos personales que hayan sido objeto de Tratamiento.</li>
          </ul>
          <h2>7. Deberes de la plataforma con relación al tratamiento de datos personales en su calidad de responsables y Encargado del Tratamiento:</h2>
          <p>De conformidad con el artículo 17 de la Ley Estatutaria 1581 de 2012, la Plataforma se compromete a cumplir los siguientes deberes:</p>
          <ul>
            <li>1. Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de hábeas data.</li>
            <li>2. Solicitar y conservar copia de la respectiva autorización otorgada por el Titular.</li>
            <li>3. Realizar en los términos previstos en los artículos 14 y 15 de la Ley Estatutaria 1581 de 2012, la actualización, rectificación o supresión de los datos.</li>
            <li>4. Tramitar las consultas y reclamos formulados por los titulares en los términos señalados en el artículo 14 de la Ley Estatutaria 1581 de 2012.Conservar la información bajo las condiciones de seguridad necesarias para impedir su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.</li>
            <li>5. Insertar en las bases de datos la leyenda “información en discusión judicial” una vez notificada por parte de la autoridad competente sobre procesos judiciales relacionados con la calidad o detalles del dato personal.</li>
            <li>6. Informar a la Superintendencia de Industria y Comercio cuando se presenten violaciones a los códigos de seguridad y existan riesgos en la administración de la información de los titulares.</li>
            <li>7. Tramitar las consultas y reclamos formulados por los titulares de la información.</li>
            <li>8. Cumplir las instrucciones y requerimientos que imparta la Superintendencia de Industria y Comercio.</li>
            <li>9. Aplicar las normas que reglamenten la Ley Estatutaria 1581 de 2012.</li>
          </ul>
          <h2>8. Autorización del Titular</h2>
          <ul>
            <li>1. Sin perjuicio de las excepciones previstas en la ley, en el Tratamiento se requiere la autorización previa, expresa e informada del Titular, la cual deberá́ ser obtenida por cualquier medio que pueda ser objeto de consulta y verificación posterior.</li>
            <li>2. La plataforma obtendrá la autorización mediante diferentes medios, entre ellos el documento físico, electrónico, mensaje de datos, Internet, Sitios Web, o en cualquier otro formato que en todo caso permita la obtención del consentimiento.</li>
          </ul>
          <h2>9. Revocatoria de autorización</h2>
          <p>Los titulares de los datos personales pueden en cualquier momento revocar la autorización otorgada a la plataforma. Asimismo, de conformidad con el artículo 15 de la Ley 1581 de 2012, el Titular que considere que la información contenida en las bases de datos debe ser objeto de corrección, actualización podrá presentar el debido reclamo ante la plataforma, mediante comunicación escrita, al correo electrónico: hola@momentu.co.</p>
          <h2>10. Aviso de privacidad</h2>
          <p>La Plataforma garantiza que los datos proporcionados por el Titular por cualquiera de los medios utilizados para recolección de datos se utilizaran exclusivamente para los fines establecidos en el presente documento, y en ninguna circunstancia se comercializarán o entregarán a terceros, con las excepcionas que la ley permita.</p>
          <h2>11. Vigencia</h2>
          <p>Esta Política General de Privacidad tiene una vigencia de carácter indefinido, y es efectiva desde el momento de su publicación en la plataforma.</p>
        </div>
      </div>
    </>
  );
}

export default HeroPolicies;
