import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import axios from 'axios';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Home from './pages/Home';
import ContactUs from './pages/Contact';
import Policies from './pages/Policies';
import BlogDetail from './pages/Blog';
import WhatsAppButton from './components/WhatsAppButton';
import { WhatsAppProvider } from './components/WhatsAppButton/context';
import './App.css';

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => {
    setIsOpen(false);
  };
  const openSidebar = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    // Obtener la IP y la geolocalización del cliente
    axios.get('https://ipinfo.io/?token=c163467ba7b124')
      .then(response => {
        // Verificar si la IP pertenece a los Estados Unidos
        if (response.data.country === 'US') {
          window.location.href = 'https://us.momentu.co';
        }
      })
      .catch(error => {
        console.error('Error al obtener la IP del cliente', error);
      });
  }, []);

  return (
    <WhatsAppProvider>
      <Router>
        <div className="App">
          <Sidebar isOpen={isOpen} onClose={toggleSidebar} />
          <Header open={openSidebar} />
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/contact-us" end element={<ContactUs />} />
            <Route path="/politica-de-privacidad" end element={<Policies />} />
            <Route path="/blog/:id" end element={<BlogDetail />} />
          </Routes>
          <WhatsAppButton />
        </div>
      </Router>
    </WhatsAppProvider>
  );
}

export default App;
