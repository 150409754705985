import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from './../components/Footer';
import { useParams } from 'react-router-dom';
import BlogListSections from './../constans/blogs';

function BlogDetail() {
  let { id } = useParams();
  const data = BlogListSections[id];
  return (
    <>
      <Helmet>
        <title>Momentu - Plataforma de Salud Empresarial Digital | Cuida la Salud de tu Equipo</title>
        <meta name="description" content="Descubre Momentu, la primera plataforma 100% digital para la salud empresarial. Reducimos los costos en beneficios mientras cuidamos la salud de tu equipo. Gestiona seguros, salud mental, conserje 24/7 y métricas de bienestar. ¡Cotiza tu seguro hoy mismo!" />
      </Helmet>
      {data && <div>
        {data.map((section) => {
          return (
            <div style={{ display: 'flex', backgroundColor: section.background, alignItems: 'center' }}>
              {
                section.text &&
                <div style={{ color: section.textColor, padding: '50px 10%' }}>
                  {section.title && <h1 style={{ textAlign: 'center' }}>{section.title}</h1>}
                  {section.subTitle && <h3>{section.subTitle}</h3>}
                  {section.text && section.text.map((text) => {
                    return <p>{text}</p>
                  })}
                  {section.list && <ul>
                    {section.list.map((item, index) => {
                      return (<li style={{ marginBottom: 16 }}>{index + 1}. {item}</li>)
                    })}
                  </ul>}
                  {section.action && <button className="benefit-button" onClick={() => {
                    window.open(section.action.url, '_blank');
                  }}>{section.action.text}</button>}
                </div>
              }
              {
                section.left &&
                <div style={{ color: section.left.textColor, padding: '50px 10%', width: '50%' }}>
                  {section.left.image && <img src={section.left.image} alt='img' style={{ maxHeight: 400, borderRadius: 16, maxWidth: '100%' }} />}
                  {section.left.title && <h1>{section.left.title}</h1>}
                  {section.left.subTitle && <h3>{section.left.subTitle}</h3>}
                  {section.left.text && section.left.text.map((text) => {
                    return <p>{text}</p>
                  })}
                  {section.left.list && <ul>
                    {section.left.list.map((item, index) => {
                      return (<li style={{ marginBottom: 16 }}>{item}</li>)
                    })}
                  </ul>}
                  {section.left.action && <button className="benefit-button" onClick={() => {
                    window.open(section.left.action.url, '_blank');
                  }}>{section.left.action.text}</button>}
                </div>
              }
              {
                section.right &&
                <div style={{ color: section.right.textColor, padding: '50px 10%', width: '50%' }}>
                  {section.right.image && <img src={section.right.image} alt={'img'} style={{ maxHeight: 400, borderRadius: 16, maxWidth: '100%' }} />}
                  {section.right.title && <h1>{section.right.title}</h1>}
                  {section.right.subTitle && <h3>{section.right.subTitle}</h3>}
                  {section.right.text && section.right.text.map((text) => {
                    return <p>{text}</p>
                  })}
                  {section.right.list && <ul>
                    {section.right.list.map((item, index) => {
                      return (<li style={{ marginBottom: 16 }}>{item}</li>)
                    })}
                  </ul>}
                  {section.right.action && <button className="benefit-button" onClick={() => {
                    window.open(section.right.action.url, '_blank');
                  }}>{section.right.action.text}</button>}
                </div>
              }
            </div>
          );
        })}
      </div>}
      <Footer />
    </>
  );
}

export default BlogDetail;
