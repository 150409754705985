import React from 'react';
import { Helmet } from 'react-helmet';
import HeroPolicies from './../components/HeroPolicies';
import Footer from './../components/Footer';

function ContactUs() {
  return (
    <>
      <Helmet>
        <title>Momentu - Plataforma de Salud Empresarial Digital | Cuida la Salud de tu Equipo</title>
        <meta name="description" content="Descubre Momentu, la primera plataforma 100% digital para la salud empresarial. Reducimos los costos en beneficios mientras cuidamos la salud de tu equipo. Gestiona seguros, salud mental, conserje 24/7 y métricas de bienestar. ¡Cotiza tu seguro hoy mismo!" />
      </Helmet>
      <HeroPolicies />
      <Footer />
    </>
  );
}

export default ContactUs;
