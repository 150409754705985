import * as images from './../images';
const BlogListSections = {
    'kpis-definitivos': [
        {
            background: '#fafafa',
            left: {
                textColor: '#061a84',
                title: 'KPIs definitivos: los indicadores que deberías estar monitorear en tu estrategia de RR.HH.',
                text: [
                    '¿Sabes cuáles son esos indicadores que son como un faro para tu estrategia? ¿Aquellos que, al medirlos, te ayudan a entender si tu empresa va marchando bien o quizá hay mejoras por hacer? Pues en este artículo exploraremos la esencia y la importancia de los Indicadores Clave de Desempeño (KPIs) para que puedas aprovechar al máximo estos datos y usarlos correctamente para llevar a tu equipo, tu empresa y tu estrategia al siguiente nivel.'
                ]
            },
            right: {
                image: images.KPI_1
            }
        },
        {
            background: '#f6f7ff',
            left: {
                image: images.KPI_2
            },
            right: {
                textColor: '#1c38a9',
                subTitle: 'La esencia transformadora de los KPIs',
                text: [
                    'Los KPIs son mucho más que cifras en una hoja de cálculo. Son la brújula que orienta el camino hacia el éxito en Recursos Humanos y en tu equipo. Estos indicadores no solo miden el rendimiento, sino que también actúan como faros que guían la estrategia y la toma de decisiones. Con ellos puedes trazar hacia dónde te diriges y qué desafíos puedes enfrentar en el camino. Son el vehículo que te lleva desde tus metas iniciales hasta el éxito deseado, proporcionando información valiosa para ajustar tu estrategia, a tu equipo y a su rendimiento. Así que...'
                ]
            }
        },
        {
            background: '#fafafa',
            textColor: '#061a84',
            title: '¿Cuáles son las principales categorías?',
            text: [
                'Si bien existen muchas categorías de indicadores que pueden ser una buena radiografía, en Momentu nos gusta enfocarnos en 5 principales que nos permiten tener un gran entendimiento. Para empezar, el reclutamiento y selección de personal se ocupa de encontrar las piezas adecuadas para el rompecabezas. Desde atraer talento mediante estrategias como publicidad de empleo y ferias laborales, hasta evaluar a los candidatos mediante entrevistas y pruebas de habilidades, esta área es clave para construir un equipo sólido.',
                'Una vez que el equipo está en su lugar, es fundamental evaluar su desempeño y productividad. El área de desempeño y productividad examina cómo los empleados cumplen con sus responsabilidades y metas. Esto implica establecer objetivos claros, proporcionar retroalimentación constructiva y analizar la eficiencia en la ejecución de tareas para garantizar que todos estén contribuyendo al máximo.',
                'La retención de talento es una batalla constante, y ahí es donde entra en juego el área de retención y rotación del equipo. ¿Cómo mantener a los empleados satisfechos y comprometidos? Esta área se centra en programas de desarrollo profesional, beneficios atractivos y la creación de un ambiente laboral positivo. Al mismo tiempo, monitorea la rotación del personal para abordar cualquier problema que pueda llevar a la pérdida de talento.',
                'El compromiso y la satisfacción del personal son factores que influyen directamente en la productividad y la cultura laboral. En la categoría de compromiso y satisfacción del personal se buscan formas de medir la conexión emocional de los empleados con su trabajo y la empresa, así como su grado de satisfacción con el entorno laboral. Esto puede incluir encuestas regulares, programas de reconocimiento y oportunidades de desarrollo.',
                'Finalmente, no se puede pasar por alto el aspecto financiero de la ecuación: la compensación y los beneficios. Este aspecto evalúa la justicia y competitividad de los salarios y beneficios ofrecidos. Un paquete de compensación atractivo es esencial para atraer y retener talento de calidad, y para asegurar que los empleados se sientan valorados.',
                'Monitorear estas cinco áreas a través de indicadores clave no solo ofrece una visión completa de la estrategia de recursos humanos, sino que también proporciona las herramientas necesarias para ajustar y mejorar continuamente las prácticas de gestión del talento.',
            ]
        },
        {
            background: '#FFFFFF',
            right: {
                textColor: '#1c38a9',
                subTitle: 'Midiendo los indicadores con precisión',
                text: [
                    'Cada una de estas categorías contiene KPI’s específicos a monitorear dentro de ella. Para tener un gran overview de toda tu empresa y tu estrategia, nuestra sugerencia es medir constantemente aquellos que te permiten tener un mejor control sobre tu empresa, objetivos y estrategia. Estos son los que desde Momentu recomendamos seguir más de cerca a la hora de tener tu estrategia en marcha.',
                ],
            },
            left: {
                image: images.KPI_3
            }
        },
        {
            background: '#fafafa',
            left: {
                textColor: '#061a84',
                subTitle: 'Reclutamiento y Selección de Personal',
                list: [
                    'Tiempo para contratación: Mide la eficiencia del proceso desde el inicio del reclutamiento hasta la contratación. Un tiempo más corto indica un proceso ágil y estructurado.',
                    'Costo por contratación: Evalúa los gastos asociados con el proceso de contratación. Un costo más bajo sugiere una gestión eficiente de recursos.',
                ]
            },
            right: {
                image: images.KPI_4
            }
        },
        {
            background: '#f6f7ff',
            right: {
                textColor: '#1c38a9',
                subTitle: 'Desempeño y Productividad',
                list: [
                    'Evaluación 360 del desempeño: Proporciona una visión completa del rendimiento, considerando opiniones de supervisores, colegas y clientes.',
                    'Productividad del empleado: Mide la eficacia y eficiencia del equipo en la generación de valor y logro de objetivos.',
                ]
            },
            left: {
                image: images.KPI_5
            }
        },
        {
            background: '#FFFFFF',
            left: {
                textColor: '#1c38a9',
                subTitle: 'Retención y Rotación del Equipo',
                list: [
                    'Expectativa de vida del colaborador: Estima cuánto tiempo un empleado podría permanecer en la empresa, basándose en la duración promedio en su rol.',
                    'Tasa de rotación: Actúa como un termómetro del flujo de entrada y salida de talento. Una disminución indica retención exitosa.',
                ]
            },
            right: {
                image: images.KPI_6
            }
        },
        {
            background: '#fafafa',
            right: {
                textColor: '#061a84',
                subTitle: 'Compensación y Beneficios',
                list: [
                    'Tasa de Utilización/Registro X Beneficio: Mide la cantidad de empleados que utilizan o se registran para beneficios en relación con el total de empleados elegibles. Indica la atracción y relevancia de los beneficios, siendo crucial para evaluar y ajustar programas.',
                    'Competitividad de la Compensación: Evalúa la competitividad de salarios y beneficios en comparación con el mercado. Sirve como guía para mantener una compensación atractiva y alineada con las prácticas actuales, favoreciendo la retención de talento.',
                    'ROI X Beneficio: Mide el retorno de inversión de los beneficios ofrecidos. Calcula el valor obtenido por cada unidad de inversión. Un alto ROI refleja impacto positivo en satisfacción y productividad, mientras que un bajo ROI indica la necesidad de ajustes para maximizar el impacto en el bienestar y motivación de los empleados.',
                ],
            },
            left: {
                image: images.KPI_7
            }
        },
        {
            left: {
                background: '#f6f7ff',
                textColor: '#1c38a9',
                subTitle: '¿Por qué estos KPIs son vitales en tu estrategia de Recursos Humanos?',
                text: [
                    'La vitalidad de los KPIs radica en su capacidad para transformar datos en conocimientos accionables. Son el medio a través del cual puedes evaluar el desempeño, identificar oportunidades de mejora y alinear tus estrategias con los objetivos empresariales más amplios. ',
                    'Un conjunto claro de KPIs alinea los objetivos del departamento con la estrategia empresarial, facilitando la evaluación de cada miembro del equipo con métricas transparentes. Además, los KPIs optimizan la asignación de recursos en Recursos Humanos, evitando derroches y maximizando la eficacia de las inversiones. ',
                    'Estos indicadores también sirven como señales tempranas de desafíos y éxitos, permitiendo ajustar estrategias de Recursos Humanos para adaptarse rápidamente a cambios en el entorno empresarial.',
                    'Con esta guía esencial, estás listo para explorar a fondo el universo de los KPIs y estructurar una estrategia sólida. Si buscas KPIs específicos para cada área mencionada, nuestro e-book te sumergirá en cada detalle del tema, y además te permitirá conocer las fórmulas exactas para calcular cada uno de los KPIs mencionados en este articulo.',
                    'Descárgalo ahora para convertirte en un verdadero profesional de los KPIs , comprendiendo cómo aplicarlos en casos reales. Recuerda, en Momentu estamos aquí para apoyarte en cada paso hacia el siguiente nivel.'
                ],
                action: {
                    text: 'Descargar e-book',
                    url: 'https://landing.momentu.co/ebook-kpis-definitivos-de-hhrr'
                }
            },
            right: {
                image: images.KPI_8
            }
        },
    ],
    'rotacion': [
        {
            background: '#fafafa',
            textColor: '#061a84',
            title: 'Todo para entender y medir correctamente la rotación de tu equipo.',
            text: [
                'En el mundo empresarial donde el éxito se moldea a través de la calidad de tu equipo, surge un factor clave que todo líder o estratega de RR.HH. debe tener en cuenta: la rotación de sus empleados.',
                'El equipo es el epicentro donde convergen los sueños, las ideas y las metas, transformándolos en realidades tangibles. Sin embargo, ¿qué sucede cuando ese equipo comienza a rotar? ¿Cuánto te cuesta reemplazar a un miembro valioso? ¿Sabes si el tiempo de contratación está afectando tus operaciones? ¿Tienes una referencia clara de tu posición en comparación con otras empresas en tu industria?',
                'En tu estrategia empresarial, estas preguntas son más que meras curiosidades. Son las claves para comprender la salud y la estabilidad de tu empresa. Porque, en última instancia, un equipo alineado, feliz y unido lleva a la empresa al siguiente nivel. Pero para lograrlo, es fundamental entender y gestionar la rotación, así que hoy en este artículo te contaremos los puntos clave que debes tener presente y te daremos acceso directo a nuestra calculadora de rotación para que midas tu tasa. ',
            ]
        },
        {
            background: '#f6f7ff',
            right: {
                image: images.ROTACION_1
            },
            left: {
                textColor: '#1c38a9',
                subTitle: '¿Por qué deberías medir la rotación?',
                text: [
                    'El equipo no solo es el corazón de tu empresa; es el motor que impulsa el éxito. La rotación, tanto voluntaria como involuntaria, puede afectar significativamente ese motor. Aquí radica la importancia de medirla.'
                ],
                list: [
                    'Gestionar los costos y la eficiencia: Conocer cuánto te cuesta reemplazar a un empleado te permite optimizar tus recursos y mejorar la eficiencia en el proceso de contratación.',
                    'Entender tu clima laboral: La rotación también es un indicador del clima laboral. Entender por qué los empleados se van puede revelar áreas de mejora en la cultura organizacional.',
                    'Benchmarking: Comparar tus tasas de rotación con las de la industria proporciona una perspectiva valiosa sobre tu posición competitiva y áreas de oportunidad.',
                ]
            }
        },
        {
            background: '#fafafa',
            right: {
                textColor: '#061a84',
                subTitle: '¿Cuáles son las causas más comunes de la rotación?',
                text: [
                    'La alta rotación puede tener diversas causas, desde falta de reconocimiento y desarrollo profesional hasta problemas de comunicación y desajustes culturales. La falta de equilibrio entre trabajo y vida personal, la insatisfacción con el liderazgo, y la ausencia de oportunidades de crecimiento son factores adicionales que pueden contribuir a un aumento en la rotación. Identificar estas causas es clave para implementar estrategias efectivas de retención y mejorar la estabilidad del equipo, ya que si no lo haces de manera sostenible podrías estar comprometiendo tu rendimiento y rentabilidad. ',
                ],
            },
            left: {
                image: images.ROTACION_2
            }
        },
        {
            background: '#fafafa',
            textColor: '#061a84',
            subTitle: 'Y si ya te está ocurriendo, tranquilo, ¡hay soluciones!',
            text: [
                'El paso más importante con la rotación no es medirla, es mitigarla. Por suerte, si crees que estás viviendo una ola de renuncias y se está dificultando tener una buena tasa de retención, es importante que, por un lado, hagas un diagnóstico de esas causas que aplican a tu empresa, y por otro, que logres implementar estrategias que motiven mucho más a tu equipo y logren retenerlo. Algunas que recomienda Harvard Business Review son las siguientes: '
            ],
            list: [
                'Crear terreno firme: El cerebro humano no fue diseñado para la incertidumbre que enfrentamos en el trabajo y la vida, según la psicóloga social Heidi Grant y Tal Goldhamer, Director de Aprendizaje para EY Americas. La rotación en tu equipo agrega a la amenaza, por lo que crea certeza donde puedas, como expresar tus planes de permanencia.',
                'Solicita feedback para evaluar capacidades: Consulta regularmente a tu equipo para entender su carga de trabajo. Esto te permitirá redistribuir tareas y comprender la capacidad colectiva. Invita al equipo a problematizar y repriorizar, motivándolos al participar en la creación de metas y generar ideas valiosas.',
                'Facilita la autonomía: Una vez alineados en metas, permite a tu equipo decidir cómo, cuándo y dónde trabajan. La flexibilidad es crucial; la autonomía, junto con la certeza, es un impulsor clave de amenaza y recompensa en el cerebro. Cuando las personas se sienten en control, están más motivadas.',
                'Da permiso para resistir: Haz saber a tu equipo que está bien decir "no" y cuestionar plazos. Da permiso explícito para resistir y repite este mensaje. Escucha sus preocupaciones, reconoce lo que dicen y participa en una conversación sobre lo que se puede hacer. Esto evita que se mantengan callados y contribuye a prevenir el agotamiento.',
                'Protege a tu equipo: Los buenos líderes protegen a sus equipos de solicitudes poco realistas. Es esencial cuando hay menos personas para soportar la misma carga de trabajo. Participa en una prioritización implacable, resiste demandas de baja prioridad y brinda a tu equipo criterios claros para decidir qué solicitudes atender.',
                'Crea conexión: Enfrentar grandes desafíos juntos y saber que otros están allí para apoyarte puede construir la moral. Fomenta un ethos de "estamos juntos en esto", donde los miembros del equipo se ayuden mutuamente, algo que puedes modelar y recompensar. Esto puede generar camaradería y amistades duraderas entre los miembros del equipo. Según Gallup, las amistades en el trabajo aumentan tanto la productividad como la participación.',
            ]
        },
        {
            background: '#f6f7ff',
            left: {
                textColor: '#1c38a9',
                subTitle: '¡Estás listo!',
                text: [
                    'Para hacer tu vida y tu estrategia más fácil, creamos nuestra Calculadora de Rotación, una herramienta poderosa diseñada para proporcionarte respuestas en minutos. Descubre todo lo que podrás medir y lograr con nuestra poderosa Calculadora de Rotación.'
                ],
                action: {
                    text: 'Descubre nuestra calculadora',
                    url: 'https://landing.momentu.co/calculadoraderotacion'
                }
            },
            right: {
                image: images.ROTACION_3
            }
        },
    ],
    'domina-tus-numeros': [
        {
            background: '#fafafa',
            textColor: '#061a84',
            title: 'Domina tus números: lo que debes saber para brillar con tu presupuesto de RR.HH.',
            text: [
                'HBR relata la historia de cuando el Museo Guggenheim de Bilbao abrió en 1997, los críticos elogiaron la obra arquitectónica de Frank Gehry como una maravilla del siglo. El gobierno había proyectado que 500,000 personas visitarían el museo cada año; sin embargo, en los primeros tres años, la afluencia alcanzó los 4 millones. El término ‘efecto Bilbao’ se popularizó en la planificación urbana y el desarrollo económico, describiendo la capacidad de una arquitectura espectacular para transformar vecindarios, ciudades y regiones.',
                'Este fenómeno proporciona un gran ejemplo para cualquier líder o estratega del mundo de los Recursos Humanos: así como el Museo Guggenheim en Bilbao superó las expectativas, un líder empresarial hábil puede utilizar estratégicamente los recursos de RR.HH para lograr un impacto que supere cualquier expectativa en la organización.',
                'Al asignar de manera eficiente los fondos disponibles y alinear las iniciativas de recursos humanos con los objetivos estratégicos de la empresa, es posible crear un efecto transformador en la productividad, el compromiso de los empleados y la cultura organizacional. ',
                'De manera similar al éxito del arquitecto Gehry, un líder de cualquier área puede brillar si sabe cómo convertir a su presupuesto en su mejor aliado.',
                'Los números y las proyecciones financieras y presupuestales son la base con la que trazarás el camino hacia tus metas, así que en este artículo te contaremos los puntos clave que debes considerar para llevar tus números a nuevas alturas, entendiéndolos a fondo y logrando crear un presupuesto sólido que impulse tus objetivos. ',
            ]
        },
        {
            background: '#f6f7ff',
            right: {
                image: images.BUDGET_1
            },
            left: {
                textColor: '#1c38a9',
                subTitle: '¿Por qué es vital contar con un presupuesto?',
                text: [
                    'Considera el presupuesto como la base que te permitirá lograr tus metas del año.  Con base en él, podrías o no acercarte con más facilidad a lo que te has planteado como meta.  Este recurso proporciona claridad sobre los objetivos del departamento en el marco de la estrategia empresarial, asegurando que todos trabajen en conjunto hacia metas comunes y sean evaluados con métricas de rendimiento claras. Facilita la identificación y asignación eficiente de los recursos necesarios, garantizando que existan fondos y capacidades disponibles para respaldar las iniciativas de Recursos Humanos. Así que si quieres lograr un cumplimiento increíble, es tu momento de convertirte en el mejor amigo de tus números.'
                ]
            }
        },
        {
            background: '#fafafa',
            left: {
                image: images.BUDGET_2
            },
            right: {
                textColor: '#061a84',
                subTitle: '¿Cómo elaborar un presupuesto efectivo?',
                text: [
                    'Enfócate en dos estrategias clave: tu presupuesto incremental y tu presupuesto basado en ceros. El primero te permitirá analizar costos y beneficios de nuevas actividades basándote en el presupuesto más reciente. Este enfoque te permite identificar necesidades emergentes, eliminar gastos innecesarios y fomentar ideas innovadoras. El segundo, por otro lado, es ideal para revisar todos los costos y beneficios, considerando solo los recursos necesarios para lograr tus objetivos de manera eficiente. Este método te desafía a repensar cada gasto, asegurando que cada inversión tenga un propósito claro.',
                    'Así logras presupuestar lo más importante desde diferentes ángulos.',
                ]
            }
        },
        {
            background: '#fafafa',
            textColor: '#061a84',
            title: 'Optimizando tu presupuesto de Recursos Humanos para el éxito empresarial',
            text: [
                'Cuando se trata de planificar el presupuesto de Recursos Humanos, es crucial identificar y priorizar los rubros que realmente marcarán la diferencia en el éxito de tu empresa. Aunque las necesidades pueden variar según la naturaleza de tu negocio, existen tres áreas clave que indudablemente deberías considerar al elaborar tu presupuesto:',
                'Incorporar el "P&L Budget x Negocio" en tu presupuesto es como personalizar una hoja de ruta para cada área o unidad de tu empresa. Esta estrategia ofrece beneficios significativos como la optimización de recursos y el control preciso de tus gastos. ',
                'Por otro lado, el planificar los costos de personal es esencial para cualquier líder de Recursos Humanos. Este aspecto clave implica la previsión y gestión de los gastos asociados a los empleados, incluyendo salarios, beneficios y capacitación. Sus beneficios son claros: el control presupuestario y la optimización de tus recursos.',
                'Además, la planificación de los costos de capacitación y desarrollo traza una hoja de ruta fundamental para el crecimiento de tu equipo. Este rubro es esencial para un líder de Recursos Humanos, ya que te permitirán cultivar tu talento, impulsar la motivación de tu equipo y mantener la competitividad a tope.',
            ]
        },
        {
            background: '#FFFFFF',
            textColor: '#1c38a9',
            title: '¿Y cuáles son los rubros más comunes en un presupuesto anual de Recursos Humanos?',
            text: [
                'Aunque estos pueden variar según las necesidades de cada empresa o industria, estos cuatro suelen ser los más comunes:',
                'Presupuesto de personal: En este segmento, estarás dando forma a la columna vertebral de tu equipo. Aquí, puedes detallar los salarios, beneficios y compensaciones necesarios para asegurarte de tener el personal adecuado en cada posición. Este presupuesto te proporciona una guía clara para planificar la fuerza laboral, evitando sorpresas y garantizando que todos estén en sus lugares para cumplir con los objetivos estratégicos.',
                'Presupuesto de capacitación y desarrollo: Considera esta sección como la academia interna de tu empresa. Aquí, destinarás recursos a programas de formación diseñados para mejorar las habilidades y competencias de tu equipo. Desde talleres especializados hasta cursos avanzados, este presupuesto asegura que tu personal esté siempre a la vanguardia, listo para enfrentar los desafíos cambiantes del mercado.',
                'Presupuesto de beneficios: Cuidar a tu equipo va más allá de los salarios. Este presupuesto incluye costos asociados a beneficios como seguros de salud, pensiones y otros incentivos. Gestionar eficientemente estos beneficios no solo contribuye a la satisfacción del empleado, sino que también juega un papel crucial en la retención de talento valioso.',
                'Presupuesto de reclutamiento: En la tarea de atraer nuevos talentos, debes destinar fondos para las tareas que esto requiere. Desde la publicidad de empleos hasta los honorarios de agencias de selección, este presupuesto te permite lanzar una red amplia y efectiva para atraer a los mejores candidatos. Así, aseguras un flujo constante de talento fresco que contribuirá al crecimiento continuo de tu empresa.',
            ],
        },
        {
            background: '#fafafa',
            left: {
                textColor: '#061a84',
                subTitle: '¿Estás listo?',
                text: [
                    'Estos son tan solo los puntos clave para iniciar tu camino como un experto en tus KPI’s, pero existe todo un universo en el que puedes profundizar y seguir mejorando tus habilidades.',
                    'Si quieres sumergirte en la estrategia de los presupuestos, no dudes en descargar nuestro E-book completo, en este recurso podrás explorar cada concepto, entender cómo mejorarlo y, como un beneficio adicional, encontrarás un presupuesto práctico de ejemplo y una sesión en video donde líderes destacados en Recursos Humanos y Finanzas aplican estos consejos en detalle en casos reales.',
                    'Y recuerda, en Momentu estamos listos para llevarte siempre al siguiente nivel. ',
                ],
                action: {
                    text: 'Descargar e-book',
                    url: 'https://landing.momentu.co/ebook-dominatusnumeros'
                }
            },
            right: {
                image: images.BUDGET_3
            }
        }
    ],
    'bienestar-como-kpi': [
        {
            background: '#fafafa',
            left: {
                textColor: '#061a84',
                title: 'El bienestar como KPI: cómo medir la satisfacción real y el potencial de tu equipo.',
                text: [
                    'En el  mundo empresarial de hoy donde las empresas compiten no solo por clientes sino también por talento, el concepto de bienestar del equipo ha emergido como una fuerza motriz clave detrás del éxito sostenible de las organizaciones.',
                    'Pero, ¿qué significa realmente el bienestar del equipo? ¿Cómo puedes medirlo y por qué es esencial para el florecimiento de tu empresa? Prepárate porque en este artículo te contaremos todo lo que necesitas saber para integrar esta métrica a tus KPI’s de tu estrategia de RR.HH.'
                ]
            },
            right: {
                image: images.KPI_WB_1
            }
        },
        {
            background: '#f6f7ff',
            left: {
                image: images.KPI_WB_2
            },
            right: {
                textColor: '#1c38a9',
                subTitle: '¿Qué es el Bienestar del Equipo?',
                text: [
                    'El bienestar del equipo es mucho más que una etiqueta de moda en el ámbito empresarial. Es el corazón que impulsa la eficiencia, el rendimiento y el buen funcionamiento de una organización, pues en últimas, una empresa exitosa lo es gracias a su gente.',
                    'Se trata de la satisfacción, el compromiso y la felicidad de los empleados en su entorno laboral. Es un mix de factores que determinan la calidad de vida laboral, la relación con los colegas, la percepción de la empresa y el equilibrio entre vida laboral y personal, y aunque no existe una única cifra universal para medirlo, sí hay varios KPI’s tradicionales que pueden crear una clara radiografía del bienestar de tu equipo. ',
                    '¡Y atención! El bienestar de un equipo va más allá de proporcionar beneficios, premios o buenos salarios. Es la creación de un entorno donde los empleados se sientan valorados, apoyados y motivados para dar lo mejor de sí mismos. Es el tejido conectivo que une a individuos con objetivos comunes y fomenta un sentido de pertenencia y propósito.',
                ]
            }
        },
        {
            background: '#FFFFFF',
            left: {
                textColor: '#1c38a9',
                subTitle: '¿Cómo medirlo?',
                text: [
                    'Medir el bienestar del equipo implica ir más allá de las métricas financieras tradicionales. Las encuestas de satisfacción laboral son una herramienta valiosa, pero para obtener una imagen completa, es crucial considerar una combinación de factores. Entre las métricas clave que tomamos en Momentu se encuentran estas tres:'
                ],
                list: [
                    'Net Promoter Score (NPS): Mide si los empleados recomendarían la empresa. Un NPS alto indica altos niveles de satisfacción y compromiso.',
                    'Rotación Estimada: Evalúa la estabilidad del equipo, midiendo la diferencia entre las nuevas incorporaciones y las salidas.',
                    'Costo de la rotación: El valor estimado en dinero que le cuesta a tu empresa costear el nivel de rotación de empleados. ',
                ]
            },
            right: {
                image: images.KPI_WB_3
            }
        },
        {
            background: '#FFFFFF',
            right: {
                textColor: '#1c38a9',
                subTitle: '¿Por qué es importante el bienestar del equipo?',
                text: [
                    'Sabemos que “bienestar” suena obvio, pues todos queremos sentirnos bien y a gusto. Pero más allá de eso, medirlo es importante ya que es una imagen clara del rendimiento y del potencial de tu gente. Si cifras como la rotación son demasiado altas o si el NPS es muy bajo, es un signo claro de que algo debe cambiar para el buen funcionamiento a largo plazo.',
                    'Así que la importancia del bienestar del equipo radica en su impacto directo en el rendimiento y la longevidad de la empresa. Un equipo satisfecho no solo es más productivo, sino que también contribuye a la retención de talento, y puede ayudarte a potenciar puntos como:',
                ],
                list: [
                    'La productividad y creatividad: Un equipo feliz tiende a ser más productivo y creativo, generando soluciones innovadoras.',
                    'La lealtad y retención: El bienestar fortalece la lealtad de los empleados, reduciendo la rotación y preservando el conocimiento interno.',
                    'La reputación de la empresa: Los empleados satisfechos se convierten en embajadores de la marca, mejorando la reputación de la empresa en el mercado laboral.',
                ]
            },
            left: {
                image: images.KPI_WB_4
            }
        },
        {
            background: '#fafafa',
            textColor: '#061a84',
            title: 'Ganando como los más grandes',
            text: [
                'Una de las mejores formas de motivarse a ser un gran promotor del bienestar es observando a los grandes referentes y sus increíbles resultados. ',
                'Observemos las prácticas de gestión de personas de  Google, conocida por su enfoque innovador. La empresa tecnológica no solo proporciona un entorno de trabajo cómodo y estimulante, sino que también ofrece beneficios atípicos, como salas de relajación, gimnasios en el lugar y programas de bienestar emocional. Esta atención al bienestar ha resultado en niveles excepcionales de satisfacción entre los empleados, contribuyendo a una cultura laboral positiva y atractiva.',
                'Otra empresa que ha destacado por su compromiso con el bienestar es Salesforce. La compañía de software ha implementado políticas que van más allá de los beneficios tradicionales, como licencia parental extendida y días de voluntariado remunerado. Este enfoque integral ha fortalecido la lealtad de los empleados y ha generado un impacto positivo en la reputación de la empresa, atrayendo talento de alta calidad.',
                'En el sector de la moda, Patagonia se presenta como un ejemplo de empresa comprometida con el bienestar de sus empleados. La marca de ropa outdoor no solo ofrece horarios de trabajo flexibles y generosos beneficios, sino que también promueve un equilibrio entre la vida laboral y personal. Este enfoque ha resultado en una fuerza laboral altamente comprometida y motivada, reflejándose en su éxito continuo y en su reputación como empleador ejemplar.',
                'Estos ejemplos ilustran cómo las empresas que priorizan el bienestar de sus equipos no solo cumplen con su responsabilidad social, sino que también obtienen beneficios tangibles en términos de desempeño empresarial. A medida que más organizaciones reconocen la conexión entre el bienestar de los empleados y el éxito a largo plazo, es probable que este enfoque se convierta en una tendencia dominante en el mundo empresarial, transformando la forma en que concebimos y gestionamos el trabajo.',
            ],
        },
        {
            background: '#fafafa',
            left: {
                textColor: '#061a84',
                subTitle: '¡Hora de ser un gran líder!',
                text: [
                    'Ir más allá de las métricas superficiales para abrazar una comprensión profunda y holística del bienestar del equipo es esencial en la era actual es esencial, y ahora lo sabes.',
                    'Comienza a valorar a tu capital humano como el activo más preciado de tu empresa e integra esta métrica esencial en tu estrategia de Recursos Humanos.',
                    'Nuestra calculadora personalizada te proporcionará las herramientas necesarias para evaluar de manera integral tus cifras clave.',
                    '¡No esperes más! Descárgala ahora y comienza a ejercer un control preciso sobre el bienestar de tu equipo, allanando el camino hacia un futuro organizacional más próspero y sostenible. ¡Tu equipo te lo agradecerá!'
                ],
                action: {
                    text: 'Conoce nuestra calculadora',
                    url: 'https://landing.momentu.co/calculadoradebienestar'
                }
            },
            right: {
                image: images.KPI_WB_5
            }
        }
    ],
    'desbloqueando-el-potencial-laboral': [
        {
            background: '#fafafa',
            left: {
                textColor: '#061a84',
                title: 'Desbloqueando el potencial laboral: lo mejor del trabajo remoto vs. presencial',
                text: [
                    'En el actual escenario laboral, el dinamismo entre el trabajo remoto y presencial ha marcado un cambio radical en la forma en que concebimos y llevamos a cabo nuestras responsabilidades profesionales. La pandemia ha sido un catalizador de esta transformación, llevándonos desde la comodidad del hogar hasta la estructura tradicional de la oficina. En este viaje de adaptación, se han revelado tendencias fundamentales que impactan directamente en la gestión de Recursos Humanos.',
                    'En nuestro más reciente E-Book del Toolkit Momentu, hemos destilado estas tendencias en datos clave y estrategias esenciales. Esta guía no solo analiza las ventajas y desventajas del trabajo remoto y presencial, sino que también proporciona las herramientas necesarias para optimizar ambas modalidades. Como líder de Recursos Humanos, esta es tu hoja de ruta para llevar la productividad, la satisfacción y el bienestar de tu equipo al siguiente nivel.',
                ],
                action: {
                    text: 'Descargar e-book',
                    url: 'https://landing.momentu.co/ebook-trabajo-presencial-vs-remoto'
                }
            },
            right: {
                image: images.POT_LAB_1
            }
        },
        {
            background: '#f6f7ff',
            left: {
                image: images.POT_LAB_2
            },
            right: {
                textColor: '#1c38a9',
                title: 'La revolución del trabajo remoto',
                text: [
                    'Con la creciente adopción del trabajo remoto, estamos viendo una reconfiguración integral en la dinámica de los entornos virtuales de trabajo. Los tradicionales escritorios y salas de conferencias virtuales están evolucionando hacia espacios colaborativos en línea y áreas individuales diseñadas para fomentar la creatividad y facilitar una concentración profunda. Este cambio no solo se trata de aspectos técnicos, sino de crear un entorno virtual que sea versátil y se adapte a las necesidades individuales de los empleados remotos. Como líder de Recursos Humanos, esto te brinda la oportunidad de diseñar un espacio remoto que no solo promueva la productividad, sino que también mejore la satisfacción de los empleados, incluso a distancia.',
                    'Esta transformación en la configuración de los espacios virtuales no es solo un ajuste superficial, sino una evolución en la cultura laboral. El trabajo remoto se redefine como un componente clave en la experiencia laboral global, y estamos todos juntos explorando y adaptándonos a estos nuevos horizontes. Algunos de los puntos más relevantes son:',
                ]
            }
        },
        {
            background: '#fafafa',
            right: {
                textColor: '#061a84',
                title: 'Flexibilidad como pilar',
                text: [
                    'El trabajo remoto ha desafiado las estructuras laborales tradicionales, dando paso a la flexibilidad como un pilar fundamental. Ahora, imagina un mundo donde los horarios de 9 a 5 son cosa del pasado, y la libertad de diseñar tu propio horario se convierte en la norma. Como líder de RRHH, este cambio ofrece una oportunidad única para impulsar la productividad y el compromiso al permitir a tus empleados adaptar su jornada laboral a sus necesidades individuales.',
                ]
            },
            left: {
                textColor: '#061a84',
                title: 'Bienestar como prioridad',
                text: [
                    'El trabajo remoto ha elevado el bienestar de los empleados a un nivel sin precedentes. Ya no se trata solo de cumplir con tareas; se trata de cuidar de la salud física y mental de cada miembro del equipo. Como líder de RR.HH., imagina implementar pausas para ejercicios de estiramiento o sesiones de meditación durante la jornada laboral. Estas acciones no solo fomentan un equilibrio saludable entre el trabajo y la vida personal, sino que también cultivan un entorno laboral donde la felicidad y el rendimiento van de la mano.',
                ]
            }
        },
        {
            background: '#fafafa',
            left: {
                textColor: '#061a84',
                title: 'El renacer de lo presencial',
                text: [
                    'La vuelta a la oficina está impulsando una reconfiguración integral en la dinámica de los espacios de trabajo. Las tradicionales estaciones de trabajo estáticas, que alguna vez definieron la rutina laboral, están dando paso a áreas colaborativas y espacios individuales diseñados para estimular la creatividad y facilitar una concentración profunda. Esta metamorfosis no  aborda el aspecto físico, sino que también se centra en la creación de un entorno laboral versátil y adaptado a las necesidades individuales. Como líder de Recursos Humanos, esta tendencia te ofrece una valiosa oportunidad para diseñar un espacio que potencie la productividad y satisfacción de los empleados.',
                    'Este cambio en la disposición de las oficinas no solo implica una alteración superficial, sino que representa una evolución en la cultura laboral, donde la presencialidad se revalúa como un componente clave en la experiencia laboral integral. Algunas de las tendencias más marcadas para esta nueva presencialidad son:'
                ]
            },
            right: {
                image: images.POT_LAB_3
            }
        },
        {
            background: '#f6f7ff',
            right: {
                textColor: '#1c38a9',
                subTitle: 'Espacios flexibles, experiencias inolvidables',
                text: [
                    'El regreso a la oficina trae consigo un cambio en la dinámica de los espacios de trabajo. Los escritorios fijos quedan atrás, dando paso a áreas de colaboración y zonas individuales que fomentan la creatividad y la concentración profunda. Como líder de RR.HH., esta tendencia ofrece la oportunidad de diseñar un entorno versátil que se adapte a las preferencias y necesidades individuales, contribuyendo a un aumento en la productividad y la satisfacción laboral.'
                ]
            },
            left: {
                textColor: '#1c38a9',
                subTitle: 'Experiencia del empleado como diferenciador',
                text: [
                    'En la actualidad, la experiencia del empleado es un diferenciador clave en la atracción y retención de talento. Se trata de crear un entorno que no solo satisfaga las necesidades profesionales, sino que también promueva el bienestar y la satisfacción general. Como líder de RR.HH., puedes liderar esta tendencia implementando programas y políticas que mejoren la calidad de vida en el trabajo, desde programas de bienestar hasta oportunidades de desarrollo profesional y una cultura inclusiva.'
                ]
            }
        },
        {
            background: '#f6f7ff',
            left: {
                textColor: '#1c38a9',
                title: 'Abrazando un futuro híbrido',
                text: [
                    'A medida que avanzamos hacia un futuro híbrido de trabajo, la clave radica en encontrar un equilibrio armonioso entre lo remoto y lo presencial.',
                    'Los líderes de RR.HH. tienen la oportunidad de implementar políticas flexibles que permitan a los empleados combinar ambas modalidades de manera eficiente.',
                    'Este enfoque no solo fomenta un ambiente laboral más adaptable sino que también permite a los empleados disfrutar de los beneficios del trabajo presencial mientras mantienen la flexibilidad del trabajo remoto.',
                    'Estos son solo los primeros destellos de las estrategias clave que te presentamos en nuestro E-Book del Toolkit Momentu. Descarga ahora esta guía completa para optimizar el trabajo remoto y presencial en tu empresa.',
                    '¿Estás listo para desbloquear el potencial laboral y llevar tu estrategia de Recursos Humanos a nuevas alturas? ¡Comencemos este viaje juntos!'
                ],
                action: {
                    text: 'Descargar e-book',
                    url: 'https://landing.momentu.co/ebook-trabajo-presencial-vs-remoto'
                }
            },
            right: {
                image: images.POT_LAB_4
            }
        }
    ],
    'calculadora-ausentismo': [
        {
            background: '#fafafa',
            left: {
                textColor: '#061a84',
                title: 'El ausentismo en tu empresa: cómo entenderlo, medirlo y combatirlo',
                text: [
                    'El ausentismo laboral se presenta como un desafío significativo que puede afectar la productividad y la salud organizacional. En Momentu, comprendemos la importancia de abordar este tema de manera estratégica, y es por eso en este artículo navegaremos el tema para entender a profundidad su importancia. Al final, podrás acceder a nuestra Calculadora de Ausentismo, una herramienta personalizada para medir, hacer seguimiento y mejorar el rendimiento de tu equipo.'
                ]
            },
            right: {
                image: images.KPI_1
            }
        },
        {
            background: '#f6f7ff',
            left: {
                image: images.KPI_2
            },
            right: {
                textColor: '#1c38a9',
                subTitle: 'Ausentismo: ¿es tan grave como parece?',
                text: [
                    'Uno de los desafíos fundamentales que enfrentan los líderes empresariales y de Recursos Humanos es el ausentismo. Este abarca cualquier falta de presentación o permanencia según lo programado, ya sea planeado o imprevisto, y en Momentu reconoce que este fenómeno va más allá de un simple número: es un indicador clave de la salud organizacional.',
                    'Los números no mienten, y cuando se trata de este fenómeno, el impacto financiero es más significativo de lo que podríamos imaginarnos. Según las estimaciones de los Centros para el Control y la Prevención de Enfermedades (CDC) en Estados Unidos, el ausentismo cuesta a los empleadores una sorprendente cifra de $225.8 mil millones anuales en pérdidas de productividad. En Europa, la Fundación Europea para la Mejora de las Condiciones de Vida y Trabajo sugiere que este costo podría alcanzar hasta el 2.5% del Producto Interno Bruto (PIB). Es por esto que cualquier líder de RR.HH. debe conocer y entender estas cifras para poder tomar decisiones a tiempo, hacer ajustar para prevenirlo y mitigarlo.'
                ]
            }
        },
        {
            background: '#FFFFFF',
            right: {
                textColor: '#1c38a9',
                subTitle: 'La tasa de ausentismo ideal: un rango a considerar',
                text: [
                    'Determinar una tasa de ausentismo “aceptable” implica un discernimiento crítico. La regla general del 1.5% de ausencia relacionada con enfermedades ofrece una guía. Este umbral indica aproximadamente cuatro días laborables al año dedicados a enfermedades y que esto sería algo completamente normal. Cualquier desviación de este límite sugiere que las causas subyacentes pueden trascender la esfera de la salud, involucrando factores personales o tensiones laborales y que debe hacerse algo al respecto.',
                    'En Momentu creemos que no existe un número universalmente ideal; sin embargo, las organizaciones podrían interpretar cualquier tasa de ausentismo agregado significativamente superior al 1.5% como una señal de alerta para intervenir.'
                ],
            },
            left: {
                image: images.KPI_3
            }
        },
        {
            background: '#fafafa',
            left: {
                textColor: '#061a84',
                subTitle: 'Reclutamiento y Selección de Personal',
                text: [
                    'Tiempo para contratación: Mide la eficiencia del proceso desde el inicio del reclutamiento hasta la contratación. Un tiempo más corto indica un proceso ágil y estructurado.',
                    'Costo por contratación: Evalúa los gastos asociados con el proceso de contratación. Un costo más bajo sugiere una gestión eficiente de recursos.',
                ]
            },
            right: {
                image: images.KPI_4
            }
        },
        {
            background: '#f6f7ff',
            right: {
                textColor: '#1c38a9',
                subTitle: 'Las causas reales del ausentismo',
                text: [
                    'La complejidad del ausentismo se manifiesta a través de una serie de factores contribuyentes. Si bien esto puede variar en cada industria, cultura y tipo de empresa, hay algunos factores recurrentes que pueden aparecer de manera común.',
                    'En algunos casos, la generosidad en la compensación por licencia médica, ejemplificada en países como Noruega, se vincula a tasas de ausentismo más elevadas. Además, las condiciones económicas ejercen su influencia; períodos de desempleo tienden a asociarse con un aumento en enfermedades y disminución de beneficios laborales.',
                    'Las diferencias de género también juegan un papel destacado, ya que las mujeres, con frecuencia, enfrentan tasas de ausentismo superiores debido a las responsabilidades de cuidado que les son relegadas a ellas.',
                    'El abuso de sustancias, condiciones médicas como la diabetes, la depresión y la edad son factores adicionales que influyen en el ausentismo. Abordar esta complejidad requiere intervenciones específicas, desde rediseños de trabajo hasta programas de bienestar, para construir un entorno laboral que fomente la salud y la productividad.',
                ]
            },
            left: {
                image: images.KPI_5
            }
        },
        {
            background: '#FFFFFF',
            left: {
                textColor: '#1c38a9',
                subTitle: 'Identificando las causas',
                text: [
                    'Identificar las causas del ausentismo en tu empresa es crucial para implementar estrategias efectivas de gestión. En primer lugar, es fundamental establecer una comunicación abierta y transparente con los empleados. A través de encuestas anónimas o sesiones de retroalimentación, brinda a tus trabajadores la oportunidad de expresar sus preocupaciones sin temor a represalias. Esto te proporcionará información valiosa sobre posibles problemas en el entorno laboral, relaciones interpersonales o cualquier otro factor que pueda contribuir al ausentismo.',
                    'En segundo lugar, analiza las tendencias y patrones en los datos de ausentismo. Utiliza herramientas analíticas para identificar picos en las tasas de ausencia y examina si existen correlaciones con eventos específicos, cambios en las políticas internas o ciclos estacionales. Esta evaluación te permitirá identificar áreas problemáticas y abordarlas de manera proactiva.',
                    'Por último, considera la implementación de programas de bienestar y salud en el lugar de trabajo. Realiza evaluaciones de riesgos laborales y encuestas de satisfacción para comprender mejor las necesidades y preocupaciones de tus empleados. Ofrecer programas que promuevan la salud física y mental puede ayudar a reducir el estrés, mejorar el equilibrio trabajo-vida y, en última instancia, disminuir las tasas de ausentismo. Al comprender y abordar las causas subyacentes, podrás crear un entorno laboral más saludable y productivo.',
                ]
            },
            right: {
                image: images.KPI_6
            }
        },
        {
            background: '#fafafa',
            right: {
                textColor: '#061a84',
                subTitle: 'Momentu: empoderando la gestión del ausentismo',
                text: [
                    'En nuestra búsqueda constante de ofrecer soluciones prácticas y efectivas, creamos una Calculadora de Ausentismo para ti. Esta herramienta, diseñada con la simplicidad y la eficiencia, te permite evaluar y comprender rápidamente la tasa de ausentismo en tu equipo. ¿Qué encontrarás en ella? '
                ],
                list: [
                    'Un Drive Sheets para personalizar la información según las necesidades específicas de tu equipo y organización.',
                    'Variables Personalizables para adaptar según las características únicas de tus empleados, garantizando mediciones precisas y relevantes.',
                    'El costo del ausentismo y % de productividad disminuida en tu empresa debido al ausentismo.',
                ],
            },
            left: {
                image: images.KPI_7
            }
        },
        {
            left: {
                background: '#f6f7ff',
                textColor: '#1c38a9',
                text: [
                    '¡Estás listo! Ahora que has entendido el impacto de este fenómenos y tienes las herramientas para medirlo, es hora de que empieces a mitigarlo en tu empresa y brilles en tu estrategia de RR.HH. implementando las estrategias que te permitirán tener un equipo feliz, constante y presente. ',
                    'Recuerda que en Momentu estamos siempre listos para hacerte la vida más fácil.',
                ],
            },
            right: {
                image: images.KPI_8
            }
        },
    ],
};
export default BlogListSections;